<template>
  <b-container fluid>
    <b-row class="row text-white bg-info">
      <b-col align-self="center" cols="2" class=" cols text-center" align-v="center">
        <h1 justify="center" align="center" class="kstyle title justify-content-md-center">Initiatives</h1>
      </b-col>
      <b-col cols="8" align-self="center" align-v="center" class="search text-center cols justify-content-md-center">
        <b-container>
          <b-row>
            <b-col>
            <b-input-group>
                <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
            </b-input-group>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        loaded: {{rows.length}} initiatives
      </b-col>
    </b-row>
    <b-row class="mb-4 text-white bg-info" v-if="!loading">
      <b-col>
        <b-container>
          <b-row>
              <b-col align-self="center" cols="8" class="cols text-center" align-v="center">
                <b-form inline>
                  <b-button v-b-toggle.sidebar-countries class="filter-button">Countries</b-button>
                  <b-button v-b-toggle.sidebar-status class="filter-button">Status</b-button>
                  <b-button v-b-toggle.sidebar-experts class="filter-button">Experts</b-button>
                  <b-button v-b-toggle.sidebar-topics class="filter-button">Topics</b-button>
                  <b-button v-b-toggle.sidebar-entites class="filter-button">Entities</b-button>
                  <b-btn class="filter-button" @click="filtersReset">show all</b-btn>
                  <b-form-select class="filter-button" v-model="viewSelected" :options="viewOptions"></b-form-select>
                </b-form>
                  <b-sidebar id="sidebar-status" title="Status" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterStatus" placeholder="Type to Search" />
                        <b-btn :disabled="!filterStatus" @click="filterStatus = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setStatusSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setStatusSelected('none')">none</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="statusSelected"
                              :options="statusOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-experts" title="Experts" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterExperts" placeholder="Type to Search" />
                        <b-btn :disabled="!filterExperts" @click="filterExperts = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setExpertSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setExpertSelected('none')">none</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="expertsSelected"
                              :options="expertsOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-topics" title="Topics" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterTopics" placeholder="Type to Search" />
                        <b-btn :disabled="!filterTopics" @click="filterTopics = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setTopicSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setTopicSelected('none')">none</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="topicSelected"
                              :options="topicOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-entites" title="Entites" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterEntities" placeholder="Type to Search" />
                        <b-btn :disabled="!filterEntities" @click="filterEntities = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setEntitySelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setEntitySelected('none')">none</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="entitySelected"
                              :options="entityOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-countries" title="Status" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
                        <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setCountriesSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setCountriesSelected('none')">none</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="countrySelected"
                              :options="countriesOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                </b-col>
                <b-col align-self="center" cols="2" class="cols text-center" align-v="center">
                  <b-btn v-if="permissions.add" class="filter-button" variant="secondary" :to="{ name: 'FormNew', params: {model: 'impactinitiative' }}">add initiative</b-btn>
                </b-col>
              </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <b-container>
          <b-row v-if="viewSelected==='list' || viewSelected==='simple'">
            <b-col cols="2">
              <b-pagination
                inline
                v-model="currentPage"
                :total-rows="showRows.length"
                :per-page="perPage"
                class="mt-3"
              ></b-pagination>
            </b-col>
            <b-col cols="4"></b-col>
            <b-col fluid cols="2" class="mt-4 text-right" justify="center" align="center">
                showing {{showRows.length}} out of {{rows.length}}
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='list'">
            <b-col>
              <b-card class="mb-3" v-for="row in showRowsPage" :key="row.id">
                <impact-initiative
                  :item="row"
                  :username="user.username"
                />
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='map'">
            <b-col>
              <vue-datamaps @showcountry="showCountry" />
              <div v-if="mapRows.length > 0">
                <b-card class="mb-3" v-for="row in mapRows" :key="row.id">
                  <impact-initiative
                    :item="row"
                    :username="user.username"
                  />
                </b-card>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='simple'">
            <b-col>
              <b-card class="mb-3">
                <div class="mb-2" v-for="row in showRowsPage" :key="row.id">
                  <router-link :to="{ name: 'ImpactInitiative', params: { id: row.id }}">{{row.name}}</router-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='by country'">
            <b-col>
              <b-card class="my-3">
                <div class="mb-3" v-for="row in showRowsByCountry" :key="row.id">
                  <h5>{{row.country}}</h5>
                  <div class="mb-3" v-for="initiative in row.initiatives" :key="initiative.id">
                    <router-link :to="{ name: 'ImpactInitiative', params: { id: initiative.id }}">{{initiative.name}}</router-link>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='list' || viewSelected==='simple'">
            <b-col>
              <b-pagination
                v-model="currentPage"
                :total-rows="showRows.length"
              :per-page="perPage"
              ></b-pagination>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='matrix'">
            <b-col>
              <div class="my-4">
                <matrix
                  :rows="showRows"
                  :dictionaries="dictionaries"
                ></matrix>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import countries from '@/libs/countries.js'
import ImpactInitiative from '@/components/ImpactInitiative.vue'
import Matrix from '@/components/Matrix.vue'
import VueDatamaps from '@/components/datamaps/Datamaps.vue'

export default {
  name: 'Initiatives',
  components: {
    ImpactInitiative,
    Matrix,
    VueDatamaps
  },
  computed: {
    countriesOptionsFilter: function () {
      if (this.rows.length > 0) {
        let options = this.rows.map(x => {
          return { value: x.country, text: x.country }
        })
        options = _.uniqBy(options, 'value')
        options = _.orderBy(options, ['text'])
        const result = _.concat([], options)
        if (this.filterCountries.length > 2) {
          let filtered = result.filter(item => item.text.toUpperCase().includes(this.filterCountries.toUpperCase()))
          return filtered
        } else {
          return result
        }
      } else {
        return []
      }
    },
    entityOptionsFilter: function () {
      if (this.filterEntities.length > 2) {
        let filtered = this.entityOptions.filter(item => item.text.toUpperCase().includes(this.filterEntities.toUpperCase()))
        return filtered
      } else {
        return this.entityOptions
      }
    },
    topicOptionsFilter: function () {
      if (this.filterTopics.length > 2) {
        let filtered = this.topicOptions.filter(item => item.text.toUpperCase().includes(this.filterTopics.toUpperCase()))
        return filtered
      } else {
        return this.topicOptions
      }
    },
    expertsOptionsFilter: function () {
      if (this.filterExperts.length > 2) {
        let filtered = this.expertsOptions.filter(item => item.text.toUpperCase().includes(this.filterExperts.toUpperCase()))
        return filtered
      } else {
        return this.expertsOptions
      }
    },
    perPage: function () {
      let result = 20
      if (this.viewSelected === 'simple') {
        result = 100
      }
      return result
    },
    rows: {
      get () {
        return this.$store.state.initiatives
      },
      set (payload) {
        this.$store.commit('setInitiatives', payload)
      }
    },
    statusOptionsFilter: function () {
      if (this.filterStatus.length > 2) {
        let filtered = this.statusOptions.filter(item => item.text.toUpperCase().includes(this.filterStatus.toUpperCase()))
        return filtered
      } else {
        return this.statusOptions
      }
    },
    showRows: function () {
      let result = this.rows

      // create pseudo countries property for matrix component
      _.each(result, x => {
        x.countries = {
          name: x.country
        }
      })

      let newResult = []
      _.each(this.countrySelected, row => {
        let filteredResult = []
        filteredResult = _.filter(result, ['country', row])
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      result = newResult

      // filter by status
      newResult = []
      _.each(this.statusSelected, row => {
        let filteredResult = []
        filteredResult = _.filter(result, ['status_id', row])
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      result = newResult

      // filter by experts
      if (this.experts.length !== this.expertsSelected.length) {
        newResult = []
        _.each(this.expertsSelected, row => {
          let filteredResult = []
          _.filter(result, x => {
            if (_.find(x.impactexperts, ['id', row])) {
              filteredResult.push(x)
            }
          })
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by topics
      if (this.topics.length !== this.topicSelected.length) {
        newResult = []
        _.each(this.topicSelected, row => {
          let filteredResult = []
          _.filter(result, x => {
            if (_.find(x.impacttopics, ['id', row])) {
              filteredResult.push(x)
            }
          })
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by topics
      if (this.entities.length !== this.entitySelected.length) {
        newResult = []
        _.each(this.entitySelected, row => {
          let filteredResult = []
          _.filter(result, x => {
            if (_.find(x.entities, ['id', row])) {
              filteredResult.push(x)
            }
          })
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      if (this.filter.length > 2) {
        result = result.filter(item => item.name.toUpperCase().includes(this.filter.toUpperCase()))
      }
      let uniqueResult = _.orderBy(_.uniqBy(result, 'id'), ['name'])
      return uniqueResult
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      console.log(this.showRows.slice(start, end))
      return this.showRows.slice(start, end)
    },
    showRowsByCountry: function () {
      let countries = this.showRows.map(x => x.country)
      countries = _.uniq(countries).sort()
      const result = []
      _.each(countries, x => {
        const row = {
          country: x,
          initiatives: _.filter(this.showRows, y => {
            return y.country === x
          })
        }
        result.push(row)
      })
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'impact initiatives', action: 'open impact initiatives' })
    this.permissions.add = ac.can(this.user.acgroups).createAny('impactinitiative').granted
    this.load()
  },
  data () {
    return {
      currentPage: 1,
      dictionaries: [],
      entities: [],
      entityOptions: [],
      entitySelected: [],
      experts: [],
      expertsOptions: [],
      expertsSelected: [],
      filterEntities: '',
      filterExperts: '',
      filterTopics: '',
      filterStatus: '',
      filterCountries: '',
      permissions: {
        add: false
      },
      countrySelected: [],
      filter: '',
      loading: true,
      mapRows: [],
      statuses: [],
      statusOptions: [],
      statusSelected: [],
      topics: [],
      topicOptions: [],
      topicSelected: [],
      viewOptions: ['list', 'map', 'matrix', 'simple', 'by country'],
      viewSelected: 'list'
    }
  },
  methods: {
    filtersReset: function () {
      this.filter = ''
      this.setCountriesSelected('all')
      this.setStatusSelected('all')
      this.setExpertSelected('all')
      this.setEntitySelected('all')
      this.setTopicSelected('all')
    },
    load: async function () {
      this.loading = true
      try {
        if (this.rows.length === 0) {
          await this.loadRows()
        }
        let countriesData = this.rows.map(x => x.country)
        countriesData = _.uniq(countriesData)
        const countryDictionary = {
          name: 'countries',
          graph: 'countries',
          data: countriesData
        }
        this.dictionaries.push(countryDictionary)
        this.statuses = await this.$Amplify.API.get('cosmos', `/standard/impactstatus`)
        const statusDictionary = {
          name: 'status',
          graph: 'impactstatus',
          data: this.statuses.map(x => x.name)
        }
        this.dictionaries.push(statusDictionary)
        _.each(this.statuses, x => {
          this.statusOptions.push({ value: x.id, text: x.name })
        })
        this.experts = await this.$Amplify.API.get('cosmos', `/standard/impactexpert`)
        const expertsDictionary = {
          name: 'experts',
          graph: 'impactexperts',
          data: this.experts.map(x => x.name)
        }
        this.dictionaries.push(expertsDictionary)
        _.each(this.experts, x => {
          this.expertsOptions.push({ value: x.id, text: x.name })
        })
        this.topics = await this.$Amplify.API.get('cosmos', `/standard/impacttopic`)
        const topicsDictionary = {
          name: 'topics',
          graph: 'impacttopics',
          data: this.topics.map(x => x.name)
        }
        this.dictionaries.push(topicsDictionary)
        _.each(this.topics, x => {
          this.topicOptions.push({ value: x.id, text: x.name })
        })
        this.entities = await this.$Amplify.API.get('cosmos', `/standard/entity`)
        const entitiesDictionary = {
          name: 'entities',
          graph: 'entities',
          data: this.entities.map(x => x.name)
        }
        this.dictionaries.push(entitiesDictionary)
        _.each(this.entities, x => {
          this.entityOptions.push({ value: x.id, text: x.name })
        })
        this.loading = false
      } catch (e) {
        this.$logger.wrn('saved ERROR: ', e)
      }
      this.setCountriesSelected('all')
      this.setEntitySelected('all')
      this.setExpertSelected('all')
      this.setTopicSelected('all')
      this.setStatusSelected('all')
    },
    loadRows: async function () {
      try {
        const limit = 200
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/impact/initiatives/limit/${limit}/offset/${offset}`)
          const rows = _.concat(this.rows, response)
          this.rows = rows
          page++
          await this.$nextTick()
        } while (response.length === limit)
        console.log(this.rows)
      } catch (e) {
        this.$logger.wrn('saved ERROR: ', e)
      }
    },
    setCountriesSelected: function (button) {
      if (button === "all") {
        this.countrySelected = []
        _.each(this.rows, x => {
          this.countrySelected.push(x.country)
        })
      }
      if (button === "none") {
        this.countrySelected = []
      }
    },
    setStatusSelected: function (button) {
      if (button === "all") {
        this.statusSelected = []
        _.each(this.statuses, x => {
          this.statusSelected.push(x.id)
        })
      }
      if (button === "none") {
        this.statusSelected = []
      }
    },
    setEntitySelected: function (button) {
      if (button === "all") {
        this.entitySelected = []
        _.each(this.entities, x => {
          this.entitySelected.push(x.id)
        })
      }
      if (button === "none") {
        this.entitySelected = []
      }
    },
    setExpertSelected: function (button) {
      if (button === "all") {
        this.expertsSelected = []
        _.each(this.experts, x => {
          this.expertsSelected.push(x.id)
        })
      }
      if (button === "none") {
        this.expertsSelected = []
      }
    },
    setTopicSelected: function (button) {
      if (button === "all") {
        this.topicSelected = []
        _.each(this.topics, x => {
          this.topicSelected.push(x.id)
        })
      }
      if (button === "none") {
        this.topicSelected = []
      }
    },
    showCountry (id) {
      const country = _.find(countries, ['alpha3', id])
      this.mapRows = _.filter(this.showRows, ['country', country.country])
    }
  }
}
</script>

<style>
body {
  padding-top: 100px;
}
h1 {
  margin: 15px;
}
.b-sidebar {
  width: 40%;
  min-width: 320px;
}
.filter {
  max-width: 253px;
}
.filter-button {
  margin-left: 25px;
  margin-top: -15px;
  margin-bottom: 15px;
}
</style>
